<mat-card appearance="outlined" class="sidreria-wrapper">
  <div>
    <img mat-card-image [src]="data?.main_photo" alt="Sidreria" />
    <div *ngIf="(price && price?.isDefinitive) || plan === 'ONLY_BUS_PLAN'" class="menu-price menu-price-definitive">
      {{ price.value }}<span class="euro">€</span>
    </div>
    <div *ngIf="price && !price?.isDefinitive && plan !== 'ONLY_BUS_PLAN'" class="menu-price menu-price-no-definitive">
      *{{ price.value }}<span class="euro">€</span>
    </div>
    <div *ngIf="isFull && plan !== 'ONLY_BUS_PLAN'" class="sidreria-full">{{ labels?.sidreriaFull }}</div>
    <div *ngIf="bookingsDate && plan !== 'ONLY_BUS_PLAN'" class="sidreria-pending-reservation">
      {{ labels?.bookingsDate }} {{ bookingsDate }}
      <span class="sidreria-pending-reservation-info">{{ labels?.bookingsDateInfo }}</span>
    </div>
  </div>

  <mat-card-content>
    <div class="sidreria-name" [matTooltip]="description">{{ data?.name }}</div>
    <div class="sidreria-description">
      {{ description }}
    </div>
    <hr />
    <div class="sidreria-options">
      <div class="sidreria-option">
        <img class="sidreria-icon" src="assets/new_assets/people_logo.png" alt="Personas" />
        <div class="option-text">{{ data?.capacity }} {{ labels?.persons }}</div>
      </div>
      <div class="sidreria-option">
        <img class="sidreria-icon" src="assets/new_assets/fork_logo.png" alt="De pie o sentado" />
        <div class="option-text">{{ standingLabel }}</div>
      </div>
      <div class="sidreria-option">
        <img class="sidreria-icon" src="assets/new_assets/phone_logo.png" alt="Teléfono" />
        <div class="option-text">{{ data?.main_phone }}</div>
      </div>
      <div class="sidreria-option">
        <img class="sidreria-icon" src="assets/new_assets/loc_logo.png" alt="Dirección" />
        <div class="option-text">{{ data?.address }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
