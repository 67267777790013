import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalSettingsService } from '@services/settings.service';

import { Sidreria } from '../../utils/entities';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'cmp-sidreria',
    templateUrl: './sidreria.component.html',
    styleUrls: ['./sidreria.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        NgIf,
        MatTooltipModule,
    ],
})
export class SidreriaComponent implements OnInit {
  /**
   * Sidreria component data
   */
  @Input() data: Sidreria;
  @Input() isFull: boolean;
  @Input() bookingsDate: string;
  @Input() labels: any;
  @Input() price;
  @Input() plan;
  /**
   * Event to emit the key of the button
   */
  @Output() buttonHandler: EventEmitter<string> = new EventEmitter<string>();

  description = '';
  standingLabel = '';

  constructor(private settingsService: LocalSettingsService) {}

  ngOnInit() {
    const currentLang = this.settingsService.getLanguage();
    this.setTranslations(currentLang);

    this.settingsService.currentLanguage.subscribe((lang: string) => {
      this.setTranslations(lang);
    });
    this.setStandingLabel();
  }

  private setTranslations(lang: string) {
    this.description = this.data.description[lang];
  }

  private setStandingLabel() {
    this.standingLabel = this.setStanding(this.data?.standing);
  }

  /**
   * Set standing text
   */
  private setStanding(standing): string {
    if (standing === 'ALL') {
      return this.labels['stand'];
    } else if (standing === 'SEATED') {
      return this.labels['sit'];
    } else if (standing === 'STANDING') {
      return this.labels['just_standing'];
    } else {
      return this.labels['stand'];
    }
  }

  /**
   * Emit data
   * @param key - Key to emit
   */
  emitButton(key: string) {
    this.buttonHandler.emit(key);
  }
}
